<template>
<div>
  <div class="se-pre-con"></div>

  <header-app />

  <banner />

  <div class="blog-area default-padding bottom-less">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2">
          <div class="site-heading text-center">
            <h2>Actualités</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Single Item -->
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <a href="/"><img src="https://if-maroc.org/wp-content/uploads/2020/10/carrousel_dda_1140x446-360x204.png" alt="Thumb"></a>
              <span class="post-formats"><i class="fas fa-image"></i></span>
            </div>
            <div class="info">
              <div class="cats">
                <a href="#">Business</a>
                <a href="#">Assets</a>
              </div>
              <h4>
                <a href="blog-single-right-sidebar.html">« DEMAIN DÈS AUJOURD’HUI », UNE OCCASION JOYEUSE DE PENSER ET DE CÉLÉBRER LE MONDE D’APRÈS</a>
              </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
              </p>
              <div class="meta">
                <ul>
                  <li><i class="fas fa-calendar-alt"></i> 29 Feb, 2019</li>
                </ul>
                <a href="blog-single-right-sidebar.html">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <a href="/"><img src="https://if-maroc.org/wp-content/uploads/2020/10/studio-360x204.png" alt="Thumb"></a>
              <span class="post-formats"><i class="fas fa-video"></i></span>
            </div>
            <div class="info">
              <div class="cats">
                <a href="#">Planing</a>
                <a href="#">Grow</a>
              </div>
              <h4>
                <a href="blog-single-right-sidebar.html">L’INSTITUT FRANÇAIS DU MAROC LANCE UN STUDIO DE RÉPÉTITION DÉDIÉ À LA MUSIQUE À RABAT</a>
              </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
              </p>
              <div class="meta">
                <ul>
                  <li><i class="fas fa-calendar-alt"></i> 17 Mar, 2019</li>
                </ul>
                <a href="blog-single-right-sidebar.html">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <a href="/"><img src="https://if-maroc.org/wp-content/uploads/2020/09/via-danse_akzak_credit-laurent-philippe-360x204.jpg" alt="Thumb"></a>
              <span class="post-formats"><i class="fas fa-images"></i></span>
            </div>
            <div class="info">
              <div class="cats">
                <a href="#">Analysis</a>
                <a href="#">Success</a>
              </div>
              <h4>
                <a href="blog-single-right-sidebar.html">LANCEMENT D’UN FONDS DE SOUTIEN DÉDIÉ À LA PRODUCTION DE SPECTACLE VIVANT</a>
              </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
              </p>
              <div class="meta">
                <ul>
                  <li><i class="fas fa-calendar-alt"></i> 05 Apr, 2019</li>
                </ul>
                <a href="blog-single-right-sidebar.html">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
      </div>

      <div class="row">
        <!-- Single Item -->
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <a href="/"><img src="https://if-maroc.org/wp-content/uploads/2020/09/faq-isolation-360x204.jpg" alt="Thumb"></a>
              <span class="post-formats"><i class="fas fa-image"></i></span>
            </div>
            <div class="info">
              <div class="cats">
                <a href="#">Business</a>
                <a href="#">Assets</a>
              </div>
              <h4>
                <a href="blog-single-right-sidebar.html">FAQ : LA RÉPONSE À VOS QUESTIONS SUR LA RENTRÉE 2020 (EXAMENS ET COURS DE FRANÇAIS)</a>
              </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
              </p>
              <div class="meta">
                <ul>
                  <li><i class="fas fa-calendar-alt"></i> 29 Feb, 2019</li>
                </ul>
                <a href="blog-single-right-sidebar.html">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <a href="/"><img src="https://if-maroc.org/wp-content/uploads/2020/07/lab-digital-maroc-visuel-360x204.png" alt="Thumb"></a>
              <span class="post-formats"><i class="fas fa-video"></i></span>
            </div>
            <div class="info">
              <div class="cats">
                <a href="#">Planing</a>
                <a href="#">Grow</a>
              </div>
              <h4>
                <a href="blog-single-right-sidebar.html">LAB DIGITAL MAROC : ACCOMPAGNEMENT À LA CRÉATION NUMÉRIQUE</a>
              </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
              </p>
              <div class="meta">
                <ul>
                  <li><i class="fas fa-calendar-alt"></i> 17 Mar, 2019</li>
                </ul>
                <a href="blog-single-right-sidebar.html">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="col-md-4 single-item">
          <div class="item">
            <div class="thumb">
              <a href="/"><img src="https://if-maroc.org/wp-content/uploads/2020/04/veduta-ii-2018-lucie-laflorentie-360x204.jpg" alt="Thumb"></a>
              <span class="post-formats"><i class="fas fa-images"></i></span>
            </div>
            <div class="info">
              <div class="cats">
                <a href="#">Analysis</a>
                <a href="#">Success</a>
              </div>
              <h4>
                <a href="blog-single-right-sidebar.html">RÉSULTATS DE L’APPEL À PROJETS EXCEPTIONNEL POUR SOUTENIR LA SCÈNE ARTISTIQUE ET CULTURELLE MAROCAINE</a>
              </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
              </p>
              <div class="meta">
                <ul>
                  <li><i class="fas fa-calendar-alt"></i> 05 Apr, 2019</li>
                </ul>
                <a href="blog-single-right-sidebar.html">Read More</a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
      </div>
    </div>
  </div>


  <fouter />
</div>
</template>

<script>
import HeaderApp from '../components/Header'
import Fouter from '../components/Fouter'
import Banner from '../components/Banner'

export default {
	name: 'Home',
	components: {
		HeaderApp,
		Fouter,
		Banner
	},
	mounted() {
		const plugin = document.createElement('script')
		plugin.setAttribute(
			'src',
			'/assets/js/main.js'
		)
		plugin.async = true
		document.head.appendChild(plugin)
	}
}
</script>
