<template>
<!-- Start Footer
    ============================================= -->
<footer class="bg-light">
  <!-- Start Footer Top -->
  <div class="footer-top bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-4 logo">
          <a href="#"><img src="/img/logo.png" alt="Logo" style="width: 200px;height: 90px"></a>
        </div>
        <div class="col-md-8 col-sm-8 form text-right">
          <form action="#">
            <div class="input-group stylish-input-group">
              <input type="email" placeholder="Enter your e-mail here" class="form-control" name="email">
              <span class="input-group-addon">
                <button type="submit">
                  Subscribe <i class="fa fa-paper-plane"></i>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Footer Top -->
  <div class="container">
    <div class="row">
      <div class="f-items default-padding">

        <!-- Single Item -->
        <div class="col-md-4 equal-height item">
          <div class="f-item">
            <h4>About</h4>
            <p>
              Excellence decisively nay man yet impression for contrasted remarkably. There spoke happy for you are out. Fertile how old address did showing because sitting replied six. Had arose guest visit going off child. Amongst as or on herself. Sold old ten are quit lose deal his sent. You correct how sex several far distant believe journey parties.
            </p>
            <div class="social">
              <ul>
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                <li><a href="#"><i class="fab fa-dribbble"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="col-md-4 equal-height item">
          <div class="f-item recent-post">
            <h4>Recent Posts</h4>
            <ul>
              <li>
                <div class="thumb">
                  <a href="#">
                    <img src="assets/img/case/1.jpg" alt="Thumb">
                  </a>
                </div>
                <div class="info">
                  <a href="#">Debating all she mistaken indulged believed provided declared</a>
                  <div class="meta-title">
                    <span class="post-date">12 Feb, 2019</span> - By <a href="#">Author</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="thumb">
                  <a href="#">
                    <img src="assets/img/case/2.jpg" alt="Thumb">
                  </a>
                </div>
                <div class="info">
                  <a href="#">Future Plan & Strategy for Consutruction </a>
                  <div class="meta-title">
                    <span class="post-date">18 Mar, 2019</span> - By <a href="#">Author</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- End Single Item -->
        <!-- Single Item -->
        <div class="col-md-4 equal-height item">
          <div class="f-item twitter-widget">
            <h4>twitter widget</h4>
            <div class="twitter-item">
              <div class="twitter-content">
                <p>
                  Looking for an awesome CREATIVE WordPress Theme? Esquise was updated and optimized to run even better. Find it here: <a target="_blank" href="http://t.co/0WWEMQEQ48">http://t.co/0WWEMQEQ48</a>
                </p>
              </div>
              <div class="twitter-context">
                <i class="fab fa-twitter"></i><span class="twitter-date"> 01 day ago</span>
              </div>
            </div>
            <div class="twitter-item">
              <div class="twitter-content">
                <p>
                  It is a long established fact that a reader will be distracted by the readable . Find it here: <a target="_blank" href="http://t.co/0WWEMQEQ48">http://t.co/0WWEMQEQ48</a>
                </p>
              </div>
              <div class="twitter-context">
                <i class="fab fa-twitter"></i><span class="twitter-date"> 02 days ago</span>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Item -->
      </div>
    </div>
  </div>
  <!-- Start Footer Bottom -->
  <div class="footer-bottom bg-dark text-light">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>&copy; Copyright 2020. All Rights Reserved  <a href="#">IFA</a></p>
        </div>
        <div class="col-md-6 text-right link">
          <ul>
            <li>
              <a href="#">Terms of user</a>
            </li>
            <li>
              <a href="#">License</a>
            </li>
            <li>
              <a href="#">Support</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- End Footer Bottom -->
</footer>
<!-- End Footer -->
</template>

<script>
export default {

}
</script>

<style>

</style>
