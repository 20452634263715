<template>
<!-- Header
    ============================================= -->
<header id="home">

  <!-- Start Navigation -->
  <nav class="navbar navbar-default attr-border navbar-sticky bootsnav">

    <div class="container">

      <!-- Start Atribute Navigation -->
      <!-- <div class="attr-nav">
        <ul>
          <li class="search"><a href="#"><i class="fa fa-search"></i></a></li>
        </ul>
      </div> -->
      <!-- End Atribute Navigation -->

      <!-- Start Header Navigation -->
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
          <i class="fa fa-bars"></i>
        </button>
        <a class="navbar-brand" href="/">
          <img src="/img/logo.png" class="logo" alt="Logo" style="width: 200px;height: 90px;">
        </a>
      </div>
      <!-- End Header Navigation -->

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="navbar-menu">
        <ul class="nav navbar-nav navbar-right" data-in="#" data-out="#">
          <li class="dropdown">
            <a href="#" class="dropdown-toggle active" data-toggle="dropdown">L'institut</a>
            <ul class="dropdown-menu">
              <li><a href="/">Présentation</a></li>
              <li><a href="/">Equipe</a></li>
              <li><a href="/">Les saveurs du savoir</a></li>
              <li><a href="/">Offres d'emploi</a></li>
            </ul>
          </li>

          <li class="dropdown">
            <a href="#" class="dropdown-toggle active" data-toggle="dropdown">LE CULTUREL !</a>
            <ul class="dropdown-menu">
              <li><a href="/">Cinéma</a></li>
              <li><a href="/">Concours</a></li>
              <li><a href="/">Conférence</a></li>
              <li><a href="/">Exposition</a></li>
              <li><a href="/">Information</a></li>
              <li><a href="/">Tout le programme</a></li>

              <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">Médiathèque</a>
                <ul class="dropdown-menu">
                  <li><a href="/">Présentation</a></li>
                  <li><a href="/">Services offerts</a></li>
                  <li><a href="/">Animations et Nouvelles acquisitions</a></li>
                  <li><a href="/">Catalogue et Médiathèque numérique</a></li>
                </ul>
              </li>

              <li><a href="/">LE CULTUREL EN LIGNE</a></li>

            </ul>
          </li>

          <!-- <li><a href="/">LE CULTUREL EN LIGNE</a></li> -->

          <!-- <li class="dropdown">
            <a href="#" class="dropdown-toggle active" data-toggle="dropdown">Médiathèque</a>
            <ul class="dropdown-menu">
              <li><a href="/">Présentation</a></li>
              <li><a href="/">Services offerts</a></li>
              <li><a href="/">Animations et Nouvelles acquisitions</a></li>
              <li><a href="/">Catalogue et Médiathèque numérique</a></li>
            </ul>
          </li> -->

          <li class="dropdown">
            <a href="#" class="dropdown-toggle active" data-toggle="dropdown">CampusFrance</a>
            <ul class="dropdown-menu">
              <li><a href="/">Présentation</a></li>
              <li><a href="/">Calendrier et procédures</a></li>
              <li><a href="/">Partenaires</a></li>
            </ul>
          </li>

          <li class="dropdown">
            <a href="#" class="dropdown-toggle active" data-toggle="dropdown">Tests et examens</a>
            <ul class="dropdown-menu">
              <li><a href="/">Retrait d'attestation TCF</a></li>
              <li><a href="/">Présentation</a></li>
              <li><a href="/">Calendriers et inscriptions</a></li>
              <li><a href="/">Résultats - Retraits diplômes / attestations</a></li>
              <li><a href="/">DELF DALF</a></li>
              <li><a href="/">TCF Epreuve complémentaire d'expression orale</a></li>
              <li><a href="/">Informations de dernière minute tests et examens</a></li>
            </ul>
          </li>

          <li class="dropdown">
            <a href="#" class="dropdown-toggle active" data-toggle="dropdown">COURS DE LANGUE </a>
            <ul class="dropdown-menu">
              <li><a href="/">Présentation</a></li>
              <li><a href="/">Première inscription aux cours de français</a></li>
              <li><a href="/">Centre des ateliers pédagogiques</a></li>
              <li><a href="/">Calendriers des sessions de cours</a></li>
              <li><a href="/">Cours juniors</a></li>
              <li><a href="/">Dernière minute</a></li>
            </ul>
          </li>
          <li>
            <a href="/">contact</a>
          </li>
        </ul>
      </div><!-- /.navbar-collapse -->
    </div>

  </nav>
  <!-- End Navigation -->

</header>
<!-- End Header -->
</template>

<script>
export default {

}
</script>

<style>

</style>
