<template>
<div>
  <div class="se-pre-con"></div>
  <banner />
</div>
</template>

<script>
import Banner from '../components/BannerSplash'

export default {
	name: 'Home',
	components: {
		Banner
	},
	mounted() {
		const plugin = document.createElement('script')
		plugin.setAttribute(
			'src',
			'/assets/js/main.js'
		)
		plugin.async = true
		document.head.appendChild(plugin)
	}
}
</script>
