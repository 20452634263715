<template>
<!-- Start Banner
    ============================================= -->
<div class="banner-area">
  <div id="bootcarousel" class="carousel text-center slide carousel-fade animate_text" data-ride="carousel">
    <!-- Wrapper for slides -->
    <div class="carousel-inner text-light carousel-zoom">
      <div class="item active">
        <div class="slider-thumb bg-cover" style="background-image: url(/img/slider-1.png);"></div>
        <div class="box-table shadow dark">
          <div class="box-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <div class="content">
                    <h1 data-animation="animated slideInDown">Consultez le programme Trimestriel de chacune de nos cinq antennes</h1>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> ALGER </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> ANNABA </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> ORAN </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> CONSTANTINE </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> TLEMCEN </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="slider-thumb bg-cover" style="background-image: url(/img/slider-2.png);"></div>
        <div class="box-table shadow dark">
          <div class="box-cell">
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <div class="content">
                    <h1 data-animation="animated slideInDown">Consultez le programme Trimestriel de chacune de nos cinq antennes</h1>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> ALGER </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> ANNABA </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> ORAN </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> CONSTANTINE </a>
                    <br>
                    <a data-animation="animated slideInUp" class="btn btn-theme effect btn-md" href="/home"> TLEMCEN </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Wrapper for slides -->

    <!-- Left and right controls -->
    <a class="left carousel-control" href="#bootcarousel" data-slide="prev">
      <i class="fa fa-angle-left"></i>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#bootcarousel" data-slide="next">
      <i class="fa fa-angle-right"></i>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
<!-- End Banner -->
</template>

<script>
export default {

}
</script>

<style scoped>
.banner-area .text-center .content a {
    margin: 15px 4px 0;
    width: 200px;
}
</style>
